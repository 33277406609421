/**
 * @requires HBS
 */
(function() {
	/**
	 * @exports DKA.main
	 */
	let module = {};

	/**
	 * Global init code for the whole application
	 */
	module.init = () => {
		module.setupTriggeredAnimationModule();
		module.videoHeroModule();
		// module.setupStickyNav();
		module.videoGridSetup();
		// module.externalLinks();
		module.setupCountdown();
		console.log("hello from home.js");
	};

	module.videoHeroModule = () => {
		let video = document.querySelector('.js-hero-video-video');
		let audio = document.querySelector('.js-audio');
		if(!audio || !video) {
			return;
		}

		audio.addEventListener('click', (e) => {
			let icon = audio.querySelector('i');
			if(icon.classList.contains('icon-volume-muted')) {
				video.muted = false;
				icon.classList.remove('icon-volume-muted');
				icon.classList.add('icon-volume-high');
			} else {
				video.muted = true;
				icon.classList.remove('icon-volume-high');
				icon.classList.add('icon-volume-muted');
			}

			e.preventDefault();
		});
	};

	module.setupStickyNav = () => {
		let hasSticky = document.querySelectorAll('.dka-sticky');
		if(hasSticky.length) {
			var sticky = new Sticky('.dka-sticky');
			let stickyContainer = document.querySelector('.js-sticky-wrap');
			let stickyElement = document.querySelector('header.dka-sticky');
			stickyContainer.setAttribute('style',`height: ${stickyElement.offsetHeight}px;`);

			window.addEventListener('resize', () => {
				sticky.update();
				stickyContainer.setAttribute('style',`height: ${stickyElement.offsetHeight}px;`)
			});
		}
	};

	module.externalLinks = () => {
		for(var c = document.getElementsByTagName("a"), a = 0;a < c.length;a++) {
			var b = c[a]; b.getAttribute("href") && b.hostname !== location.hostname && (b.target = "_blank")
		}
	};

	module.videoGridSetup = () => {
		let micromodal = document.querySelector('.js-micro-modal');

		if(!micromodal) {
			return;
		}

		function modalShow(modal) {
			let videoSrc = modal.getAttribute('data-src');
			let youtubeIframe = modal.querySelector('.js-youtube-embed');
			if(!youtubeIframe) {
				return;
			}

			youtubeIframe.setAttribute('src', videoSrc + "?autoplay=1" );
		}

		function modalClose(modal){
			let youtubeIframe = modal.querySelector('.js-youtube-embed');

			if(!youtubeIframe) {
				return;
			}

			//stop the video when closed
			youtubeIframe.setAttribute('src', '');

		}

		MicroModal.init({
			onShow: modal => modalShow(modal), // [1]
			onClose: modal => modalClose(modal), // [2]
		});
	};

	module.setupTriggeredAnimationModule = () => {
		let videoModule = document.querySelector('.js-triggered-animation');

		if(!videoModule) {
			return;
		}

		let offset = (el) => {
			var rect = el.getBoundingClientRect(),
				scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
				scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
		};
		let videoModuleTop = offset(videoModule).top;
		let video = document.querySelector('.js-ta-video');
		let mobileSrc = video.getAttribute('data-mobile-src');
		let desktopSrc = video.getAttribute('data-desktop-src');
		let ran = false;

		let scrollFunc = () => {
			let scrollPos = window.pageYOffset || document.documentElement.scrollTop;

			//play video when the module is 30% in the viewport;
			if(scrollPos > (videoModuleTop - (document.documentElement.clientHeight * 0.8)) && ran !== true ) {
				console.log('got to initialization point');
				if ( video ) {
					video.pause();
					video.currentTime = 0;
					video.play();
				}
				ran = true;
			}
		};

		window.addEventListener('scroll', scrollFunc);

		let checkWidths = () => {
			if (window.innerWidth < 580 && video.getAttribute('src') !== mobileSrc) {
				video.setAttribute('src', mobileSrc);
				video.pause();

			} else if (window.innerWidth > 579 && video.getAttribute('src') !== desktopSrc) {
				video.setAttribute('src', desktopSrc);
				video.pause();
			}
		};

		window.addEventListener('resize', () => {
			checkWidths();
		});

		checkWidths();
	};

	module.setupCountdown = () => {
		
		// The data/time we want to countdown to
		var countDownDate = new Date("Oct 1, 2020 10:00:00").getTime();

		// Run myfunc every second
		var myfunc = setInterval(function() {

		var now = new Date().getTime();
		var timeleft = countDownDate - now;
			
		// Calculating the days, hours, minutes and seconds left
		var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
		var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
		var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
			
		// Result is output to the specific element
		document.getElementById("days").innerHTML = days + "<span class='video-hero__unit'>days</span>"
		document.getElementById("hours").innerHTML = hours + "<span class='video-hero__unit'>hrs</span>"
		document.getElementById("mins").innerHTML = minutes + "<span class='video-hero__unit'>min</span>"
		document.getElementById("secs").innerHTML = seconds + "<span class='video-hero__unit'>sec</span>"
			
		// Display the message when countdown is over
		if (timeleft < 0) {
			clearInterval(myfunc);
			document.getElementById("days").innerHTML = ""
			document.getElementById("hours").innerHTML = "" 
			document.getElementById("mins").innerHTML = ""
			document.getElementById("secs").innerHTML = ""
			document.getElementById("end").innerHTML = "";
		}
		}, 1000);
					
	};

	/**
	 * Initialize the app and run the bootstrapper
	 */
	 var docReady = (callback) => {
		// in case the document is already rendered
		if (document.readyState !== 'loading') {
			callback();
		} else if (document.addEventListener) {
			// modern browsers
			document.addEventListener('DOMContentLoaded', callback);
		} else {
			// IE <= 8
			document.attachEvent('onreadystatechange', () => {
				if (document.readyState === 'complete') callback();
			});
		}
	};



	docReady(() => {
		module.init();
		//HBS.initPage();
	});

	HBS.namespace('DKA.main', module);
}());